<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición de la Definición de la Consulta {{ consulta.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container v-if="!loadingSave">

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="consulta.nombre"
                label="Nombre de la consulta"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="consulta.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingCategorias"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCategorias"
                v-model="consulta.categoria_id"
                :items="categorias"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Categoría"
                :rules="required"     
              ></v-select>
            </v-col> 
          
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="consulta.layout_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Layout AF"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="consulta.campos_consulta"
                :items="campos"
                :item-text="'nombre_alias'"
                :item-value="'nombre'"
                attach
                chips
                label="Campos consulta"
                :rules="required"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="consulta.campos_panoramico_1"
                :items="campos"
                :item-text="'nombre_alias'"
                :item-value="'nombre'"
                attach
                chips
                :label="labelsPanoramicos[0]?labelsPanoramicos[0].valor_texto:'...'"
                :rules="required"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="consulta.campos_panoramico_2"
                :items="campos"
                :item-text="'nombre_alias'"
                :item-value="'nombre'"
                attach
                chips
                :label="labelsPanoramicos[1]?labelsPanoramicos[1].valor_texto:'...'"
                :rules="required"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="consulta.campos_panoramico_3"
                :items="campos"
                :item-text="'nombre_alias'"
                :item-value="'nombre'"
                attach
                chips
                :label="labelsPanoramicos[2]?labelsPanoramicos[2].valor_texto:'...'"
                :rules="required"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="consulta.campos_panoramico_4"
                :items="campos"
                :item-text="'nombre_alias'"
                :item-value="'nombre'"
                attach
                chips
                :label="labelsPanoramicos[3]?labelsPanoramicos[3].valor_texto:'...'"
                :rules="required"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="consulta.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <!-- <pre>{{consulta}}</pre> -->

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import definicionConsulta from "@/services/definicionConsulta.service";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consulta: {
        nombre: undefined,
        descripcion: undefined,
        categoria_id: undefined,
        layout_id: undefined,
        campos_consulta: [],
        campos_panoramico_1: [],
        campos_panoramico_2: [],
        campos_panoramico_3: [],
        campos_panoramico_4: [],
        roles_acceso: [],
        activo: true,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      fuentes: [],
      categorias: [],
      labelsPanoramicos: [],
      campos: [],
      loadingLayouts: false,
      loadingCategorias: false,
      loadingLabelsPanoramicos: false,
      loadingCampos: false,
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "consulta.layout_id"(newVal) {
      this.fetchCampos("");
    },
  },
  mounted() {
    if (!this.permissions.includes("Autogestor - Definición de consulta"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_autogestor_preconsulta")) {
      this.consulta = JSON.parse(
        localStorage.getItem("datos_autogestor_preconsulta")
      );
      this.consulta.campos_consulta = JSON.parse(this.consulta.campos_consulta);
      this.consulta.campos_panoramico_1 = JSON.parse(this.consulta.campos_panoramico_1)
      this.consulta.campos_panoramico_2 = JSON.parse(this.consulta.campos_panoramico_2)
      this.consulta.campos_panoramico_3 = JSON.parse(this.consulta.campos_panoramico_3)
      this.consulta.campos_panoramico_4 = JSON.parse(this.consulta.campos_panoramico_4)
      this.consulta.roles_acceso = JSON.parse(this.consulta.roles_acceso)
    }

    this.fetchLayouts("")
    this.fetchCategorias("")
    this.fetchLabelsPanoramicos("")
    
  },
  methods: {
    save() {
      this.loadingCampos = false
      this.consulta.campos_consulta = JSON.stringify(this.consulta.campos_consulta)
      this.consulta.campos_panoramico_1 = JSON.stringify(this.consulta.campos_panoramico_1)
      this.consulta.campos_panoramico_2 = JSON.stringify(this.consulta.campos_panoramico_2)
      this.consulta.campos_panoramico_3 = JSON.stringify(this.consulta.campos_panoramico_3)
      this.consulta.campos_panoramico_4 = JSON.stringify(this.consulta.campos_panoramico_4)
      this.consulta.roles_acceso = JSON.stringify(this.consulta.roles_acceso)

      console.log(this.consulta)

      this.loadingSave = true;
      if (this.consulta.hasOwnProperty("id")) {
        definicionConsulta.editItem(this.consulta).then(
          (response) => {
            this.$router.push({ path: "/autogestor/gestor-consultas" });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              console.log("presentación del error:")
              console.log(error)
          }
        );
      } else {
        definicionConsulta.newItem(this.consulta).then(
          (response) => {
            this.$router.push({ path: "/autogestor/gestor-consultas" });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if (cadenaBusqueda == "") cadenaBusqueda = "?activo=1";
      else cadenaBusqueda = cadenaBusqueda + "&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchCategorias(cadenaBusqueda) {
      this.loadingCategorias = true;
        if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      ListaService.getCategoriasConsultas(cadenaBusqueda).then(
        (response) => {
          this.categorias = response.data.data;
          this.loadingCategorias = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCategorias = false;
        }
      );
    },
    fetchLabelsPanoramicos(cadenaBusqueda) {
      this.loadingLabelsPanoramicos = true;
        if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      ListaService.getLabelsPanoramicos(cadenaBusqueda).then(
        (response) => {
          this.labelsPanoramicos = response.data.data;
          this.loadingLabelsPanoramicos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLabelsPanoramicos = false;
        }
      );
    },
    fetchCampos(cadenaBusqueda) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      CampoService.getItems(this.consulta.layout_id, cadenaBusqueda).then(
        (response) => {
          // this.campos = response.data;
          this.campos = response.data.map(
            function f(x) {
              let alias = x.alias?`(${x.alias})`:''
              return { ...x, nombre_alias: `${x.nombre} ${alias}` };
            }
          );
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/autogestor/gestor-consultas" });
    },
  },
};
</script>

<style>
</style>
